.shake {
    animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0) rotate(3deg);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0) rotate(-3deg);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0) rotate(3deg);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0) rotate(-3deg);
    }
  }